<template>
  <!-- 模板 -->
  <div class="moban">
    <div class="moban-head"></div>
    <div class="moban-nav">
      <div class="moban-nav-left">
        <div class="nav_list">
          <div class="nav_list_1">
            <div class="list1_title">模板类型</div>
            <div class="list_btn">
              <span
                v-for="(item, idx) in allList"
                :key="idx"
                @click="onSwitchHy(item, idx)"
                :class="[currentId == item.id ? 'active_style' : '']"
                >{{ item.name }}</span
              >
            </div>
          </div>
          <div class="list_line nav_list_2">
            <div class="list2_title">
              <img src="~@/assets/image/moban/hangye-1.png" alt="" />
              <span>行业</span>
            </div>
            <div
              class="list_btn"
              :style="{ height: status ? 'auto' : '320px' }"
            >
              <span
                v-for="(item, idx) in industryList"
                :class="[currentId2 == item.id ? 'active_style' : '']"
                :key="idx"
                @click="onSwitchList(item, idx)"
                >{{ item.name }}</span
              >
            </div>
            <div class="list2_btn" v-if="industryList.length > 16">
              <span @click="status = !status"
                >{{ status ? '收起行业' : '展开行业' }} >></span
              >
            </div>
          </div>
          <div class="list_line nav_list_3">
            <div class="list_search">
              <el-autocomplete
                class="inline-input"
                v-model="searchNr"
                :fetch-suggestions="querySearch"
                placeholder="请输入名称"
                :trigger-on-focus="false"
                clearable
              ></el-autocomplete>
            </div>
            <div class="list_searchico centerText" @click="onsearch()">
              <img src="~@/assets/image/moban/sousuo-ico.png" alt="" />
            </div>
          </div>
          <div class="list_line nav_list_4">
            <a href="#/aboutuser" @click="toLink(33)"
              >了解更多我们公司的信息？</a
            >
            <a href="#/contactUs" @click="toLink(41)"
              >网站开发随时联系，随时沟通效果会更好哦~</a
            >
          </div>
        </div>
      </div>
      <div class="moban-sj-left">
        <div class="nav_list">
          <div class="nav_list_1" @click="type_show = !type_show">
            <div class="list1_title">
              <i
                class="el-icon-search"
                style="font-size:0.8rem;vertical-align: text-top;"
              ></i>
              模板类型
            </div>
          </div>
          <transition name="fadeHeight" mode="out-in">
            <div class="nav_list_show" v-if="type_show">
              <div class="nav_list_2">
                <div class="list_btn">
                  <span
                    v-for="(item, idx) in industryList"
                    :key="idx"
                    @click="onSwitchList(item, idx)"
                    >{{ item.name }}</span
                  >
                </div>
              </div>
              <div class="list_line nav_list_3">
                <div class="list_search">
                  <el-input
                    placeholder="请输入名称"
                    v-model="searchNr"
                    clearable
                  >
                  </el-input>
                </div>
                <div class="list_searchico centerText" @click="onsearch">
                  <img src="~@/assets/image/moban/sousuo-ico.png" alt="" />
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>
      <div class="moban-nav-right">
        <div class="nav-mb" v-if="mobanList.length != 0">
          <ul>
            <li v-for="(item, idx) in mobanList" :key="idx">
              <div class="moban_content">
                <a :href="item.link" target="_blank">
                  <div class="moban_content_tp">
                    <img
                      src="@/assets/image/moban/moban-pc-bg.png"
                      alt="背景图"
                      class="list_img"
                    />
                    <img :src="item.site_images" alt="" class="list_img2" />
                    <div class="list_yl">
                      <div class="yulan-an centerText">
                        <div class="yulan-an-logo">
                          <img
                            src="@/assets/image/svg/yulan.svg"
                            alt=""
                            style="width:100%;height:100%"
                          />
                        </div>
                        <span>预览</span>
                      </div>
                    </div>
                  </div>
                  <div class="moban_sj_content_tp">
                    <img :src="item.site_images" alt="" style="width:100%" />
                  </div>
                </a>
                <div class="moban_content_text">
                  <div class="text_upper">
                    <div class="text_upper_left">
                      <p style="margin-bottom: 8px;">名称：{{ item.title }}</p>
                      <p style="font-size: 12px;color: #959595;">
                        当前编号：XF_{{
                          item.id >= 10 ? item.id : '0' + item.id
                        }}
                      </p>
                    </div>
                    <div class="text_upper_right centerText">
                      ￥{{ item.price }}元
                    </div>
                  </div>
                  <div class="text_lower">
                    <div>
                      <span>适用设备：</span>
                      <img src="@/assets/image/svg/sanheyi.svg" alt="" />
                      <span style="color: #ec393b;">
                        {{ item.client_ids.indexOf('15') != -1 ? '电脑、' : ''
                        }}{{
                          item.client_ids.indexOf('16') != -1 ? '手机、' : ''
                        }}{{
                          item.client_ids.indexOf('16') != -1 ? '小程序' : ''
                        }}</span
                      >
                    </div>
                    <p style="font-size: 12px;color: #959595;">
                      浏览次数：{{ item.reads }}
                    </p>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="nav-notHave centerText" v-else>
          <img
            src="@/assets/image/xwdt/xwdt-data.webp"
            alt=""
            style="width:50%"
          />
        </div>
        <div class="all-pagination" v-if="tabPag.total">
          <el-pagination
            prev-text="上一页"
            next-text="下一页"
            @current-change="handleCurrentChange"
            :current-page.sync="tabPag.page"
            background
            :page-size="8"
            layout="prev, pager, next,total"
            :total="tabPag.total"
            v-if="tabPag.total > 8"
          >
          </el-pagination>
          <span class="no_meet_conditions" v-else>暂无更多数据</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getMobanList,
  getIndustryList,
  getMobanData,
  searchMoban
} from '@/api/api'
export default {
  components: {},
  data () {
    return {
      allList: [],
      industryList: [],
      mobanList: [],
      status: false,
      type_show: false,
      searchNr: '',
      tabPag: {
        page: 1,
        total: 0 //总数
      },
      loading: false,
      currentId: '',
      currentId2: '',
      restaurants: []
    }
  },
  methods: {
    getData () {
      if (this.$route.query.id) {
        this.currentId = this.$route.query.id
        this.mobanData(1, this.currentId)
      }else{
        this.mobanData(1, '')
      }
      this.getIndustryData('')
      if (this.$route.query.id2) {
        this.currentId2 = this.$route.query.id2
        this.getHyData(1, this.currentId2)
      }
    },
    getIndustryData (i) {
      let that = this
      getIndustryList(i).then(res => {
        if (res.data.code == 1) {
          res.data.data.clientList.unshift({
            name: '全部',
            id: ''
          })
          that.allList = res.data.data.clientList
          that.industryList = res.data.data.industryList
        }
      })
    },
    getHyData (i, x) {
      getMobanData(i, x).then(res => {
        if (res.data.code == 1) {
          this.tabPag.total = res.data.data.total
          this.mobanList = res.data.data.list
        }
      })
    },
    toLink (i) {
      sessionStorage.setItem(this.changeData() + 'id', i)
    },
    mobanData (i, x) {
      getMobanList(i, x).then(res => {
        if (res.data.code == 1) {
          this.restaurants = []
          this.tabPag.total = res.data.data.total
          this.mobanList = res.data.data.list
          res.data.data.list.forEach(el => {
            if (el.title) {
              let obj = {
                value: ''
              }
              obj.value = el.title
              this.restaurants.push(obj)
            }
          })
        }
      })
    },
    onsearch () {
      searchMoban(this.searchNr).then(res => {
        if (res.data.code == 1) {
          this.tabPag.total = res.data.data.total
          this.mobanList = res.data.data.list
        }
      })
    },
    querySearch (queryString, cb) {
      var restaurants = this.restaurants
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    createFilter (queryString) {
      return restaurant => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        )
      }
    },
    onSwitchHy (e) {
      this.currentId = e.id
      this.$router.push({
        query: { id: e.id }
      })
      this.mobanData(1, e.id)
    },
    onSwitchList (e) {
      this.currentId2 = e.id
      this.getHyData(1, e.id)
      this.$router.push({
        query: { id: this.currentId, id2: e.id }
      })
    },
    handleCurrentChange (val) {
      this.loading = true
      if (this.currentId) {
        this.mobanData(val, this.currentId)
      } else if (this.currentId2) {
        this.getHyData(val, this.currentId2)
      } else {
        this.mobanData(val, '')
      }
    }
  },
  mounted () {
    this.getData()
  }
}
</script>

<style lang="less" scoped>
@import url('~@/assets/css/mixins.less'); //混合
.moban {
  width: 100%;
  overflow: hidden;
  .moban-head {
    height: 80px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.6);
  }
  .moban-nav {
    background-color: #f5f5f5;
    padding: 20px 18px;
    padding-bottom: 50px;
    display: flex;
    justify-content: space-between;
    .moban-nav-left {
      box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1);
    }
    .moban-nav-left,
    .moban-sj-left {
      width: 271px;
      margin-right: -100%;
      background: #fff;
      position: relative;
      .nav_list {
        padding: 0 13px;
        font-size: 12px;
        .list_btn {
          span {
            cursor: pointer;
            height: 12px;
            display: inline-block;
            border: 1px solid #dcdcdc;
            padding: 8px 10px;
            border-radius: 2px;
            font-size: 12px;
            margin: 0 3px;
            margin-bottom: 10px;
            color: #535353;
          }
          span:hover {
            background-color: #e62022;
            border: 1px solid #e62022;
            color: #fff;
          }
          .active_style {
            background-color: #e62022;
            border: 1px solid #e62022 !important;
            color: #fff !important;
          }
        }
        .nav_list_1 {
          padding-top: 15px;
          padding-bottom: 10px;
          .list1_title {
            text-align: center;
            text-indent: 0px;
            background-color: #e62022;
            color: #fff;
            width: calc(100% - 6px);
            font-size: 14px;
            padding: 12px 0;
            cursor: pointer;
            box-sizing: border-box;
            margin: 0 3px;
            margin-bottom: 15px;
          }
        }
        .nav_list_2 {
          padding: 15px 0;
          .list2_title {
            font-size: 14px;
            margin-bottom: 15px;
            img {
              vertical-align: sub;
              margin-right: 15px;
            }
          }
          .list_btn {
            overflow: hidden;
            span {
              cursor: pointer;
              display: inline-block;
              padding: 8px 16px;
            }
          }
          .list2_btn {
            margin-top: 10px;
            text-align: center;
            span {
              background-color: #f4f4f4;
              display: inline-block;
              margin: auto;
              width: 88%;
              border-radius: 30px;
              padding: 10px 0;
              color: #333;
              cursor: pointer;
            }
            span:hover {
              background-color: #f0f0f0;
            }
          }
        }
        .nav_list_3 {
          width: 100%;
          padding: 15px 0;
          display: flex;
          .list_search {
            width: 80%;
            .el-input {
              font-size: 12px;
              .el-input__inner {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
              }
            }
          }
          .list_searchico {
            width: 20%;
            border: 1px solid #ddd;
            border-left: 0;
            cursor: pointer;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
          }
        }
        .nav_list_4 {
          padding: 20px 0;
          a {
            display: block;
            text-align: center;
            color: #535353;
            text-decoration: underline;
            font-size: 12px;
            line-height: 2;
          }
        }
        .list_line {
          border-top: 1px solid #dcdcdc;
          box-sizing: border-box;
        }
      }
    }
    .moban-sj-left {
      width: 100%;
      display: none;
      .nav_list {
        padding: 0 0.6rem;
        padding-bottom: 1rem;
        font-size: 0.6rem;
        .nav_list_1 {
          padding: 0.8rem 0;
          .list1_title {
            font-size: 0.7rem;
            padding: 0.6rem 0;
            margin-bottom: 0;
          }
        }
        .nav_list_show {
          max-height: 17rem;
          .nav_list_2 {
            padding: 0.6rem 0;
            padding-bottom: 0.5rem;
            .list_btn {
              height: 10rem;
              span {
                display: inline-block;
                height: 0.6rem;
                padding: 0.4rem 0.6rem;
                border-radius: 0.1rem;
                font-size: 0.6rem;
                margin: 0 0.3rem;
                margin-bottom: 0.5rem;
              }
            }
          }
          .nav_list_3 {
            padding: 0.5rem 0;
            .list_search {
              .el-input {
                font-size: 0.6rem;
                .el-input__inner {
                  border-radius: 0.2rem;
                  border-top-right-radius: 0;
                  border-bottom-right-radius: 0;
                  height: 2rem;
                  line-height: 2rem;
                  padding: 0 0.6rem;
                }
              }
            }
            .list_searchico {
              img {
                width: 1.2rem;
              }
            }
          }
        }
        .fadeHeight-enter-active,
        .fadeHeight-leave-active {
          transition: all 0.5s;
          height: 14rem;
        }
        .fadeHeight-enter,
        .fadeHeight-leave-to {
          height: 0;
          opacity: 0;
        }
      }
    }
    .moban-nav-right {
      width: calc(100% - 340px);
      background: #fff;
      padding: 25px 20px;
      box-shadow: 4px 3px 10px rgba(0, 0, 0, 0.1);
      .nav-mb {
        ul {
          min-height: 574px;
          display: flex;
          flex-wrap: wrap;
          li {
            width: 25%;
            margin-bottom: 35px;
            .moban_content {
              padding: 0 5px;
              .moban_content_tp {
                position: relative;
                .list_img {
                  width: 100%;
                  position: relative;
                  z-index: 9;
                }
                .list_img2 {
                  position: absolute;
                  left: 11.2%;
                  top: 5.85%;
                  width: 78%;
                  z-index: 99;
                  height: 87%;
                }
                .list_yl {
                  position: absolute;
                  left: 11.2%;
                  top: 5.85%;
                  bottom: 6.5%;
                  width: 78%;
                  z-index: 999;
                  background-color: rgba(0, 0, 0, 0.2);
                  display: flex;
                  justify-content: center;
                  flex-direction: column;
                  opacity: 0;
                  transition: 0.5s;
                  .yulan-an {
                    width: 100px;
                    height: 100px;
                    border-radius: 50%;
                    background-color: rgba(255, 255, 255, 0.9);
                    flex-direction: column;
                    color: #ec393b;
                    margin: auto;
                    .yulan-an-logo {
                      width: 40px;
                      height: 40px;
                    }
                    span {
                      font-size: 14px;
                      margin-top: 3px;
                    }
                  }
                }
                .list_yl:hover {
                  opacity: 1;
                }
                .list_yl::after {
                  content: '';
                  height: 5%;
                  background: #333;
                  filter: blur(10px);
                  position: absolute;
                  left: 10%;
                  right: 10%;
                  bottom: 0;
                  -webkit-border-radius: 100%;
                  -moz-border-radius: 100%;
                  border-radius: 100%;
                }
              }
              .moban_content_tp:after {
                content: '';
                height: 5%;
                background: #333;
                filter: blur(10px);
                position: absolute;
                left: 10%;
                right: 10%;
                bottom: 0;
                border-radius: 100%;
              }
              .moban_sj_content_tp {
                display: none;
                position: relative;
                border: 1px solid #ddd;
                overflow: hidden;
                img {
                  height: 6rem;
                }
              }
              .moban_content_text {
                margin-top: 30px;
                padding: 0 10%;
                font-size: 14px;
                color: #666;
                .text_upper {
                  display: flex;
                  justify-content: space-between;
                  .text_upper_left {
                    width: 60%;
                    p {
                      height: 15px;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    }
                  }
                  .text_upper_right {
                    color: #ec393b;
                    padding: 0 5px;
                    height: 24px;
                    border: 1px solid #ec393b;
                    border-radius: 5px;
                    cursor: pointer;
                    text-align: center;
                  }
                }
                .text_lower {
                  margin-top: 10px;
                  color: #535353;
                  font-size: 12px;
                  line-height: 24px;
                  i {
                    color: #ec393b;
                    font-size: 20px;
                    vertical-align: top;
                  }
                }
              }
            }
          }
        }
      }
      .nav-notHave {
        width: 100%;
        height: calc(100% - 34px);
      }
    }
  }
}
</style>
